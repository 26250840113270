$border: #DDDDDD;
$primary: #FF9803;
$bg_light: #F5F5F5;
$promo: #2EA972;
$color_main: #000;
$color_secondary: #666666;
$hover: #FFF0D9;

@font-face {
    font-family: 'barlow_semi_condensedbold';
    src: url('../fonts/barlowsemicondensed-bold-webfont.eot');
    src: url('../fonts/barlowsemicondensed-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowsemicondensed-bold-webfont.woff2') format('woff2'),
        url('../fonts/barlowsemicondensed-bold-webfont.woff') format('woff'),
        url('../fonts/barlowsemicondensed-bold-webfont.ttf') format('truetype'),
        url('../fonts/barlowsemicondensed-bold-webfont.svg#barlow_semi_condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlow_semi_condensedmedium';
    src: url('../fonts/barlowsemicondensed-medium-webfont.eot');
    src: url('../fonts/barlowsemicondensed-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowsemicondensed-medium-webfont.woff2') format('woff2'),
        url('../fonts/barlowsemicondensed-medium-webfont.woff') format('woff'),
        url('../fonts/barlowsemicondensed-medium-webfont.ttf') format('truetype'),
        url('../fonts/barlowsemicondensed-medium-webfont.svg#barlow_semi_condensedmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlow_semi_condensedregular';
    src: url('../fonts/barlowsemicondensed-regular-webfont.eot');
    src: url('../fonts/barlowsemicondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowsemicondensed-regular-webfont.woff2') format('woff2'),
        url('../fonts/barlowsemicondensed-regular-webfont.woff') format('woff'),
        url('../fonts/barlowsemicondensed-regular-webfont.ttf') format('truetype'),
        url('../fonts/barlowsemicondensed-regular-webfont.svg#barlow_semi_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlow_semi_condensedsemibold';
    src: url('../fonts/barlowsemicondensed-semibold-webfont.eot');
    src: url('../fonts/barlowsemicondensed-semibold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/barlowsemicondensed-semibold-webfont.woff2') format('woff2'),
        url('../fonts/barlowsemicondensed-semibold-webfont.woff') format('woff'),
        url('../fonts/barlowsemicondensed-semibold-webfont.ttf') format('truetype'),
        url('../fonts/barlowsemicondensed-semibold-webfont.svg#barlow_semi_condensedsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

html,
body {
    height: 100%;
}

body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color_main;
    font-family: 'barlow_semi_condensedregular';
    font-size: 18px;
}

a {
    color: $primary;
    text-decoration: none;
}

strong {
    font-family: 'barlow_semi_condensedbold';
}

@mixin borderBox() {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

.padding {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 36px;
}

.btn.btn-primary {
    background: $primary;
    color: white;
    border: none;
    @include border-radius(1px);
    font-family: 'barlow_semi_condensedmedium';
    text-transform: uppercase;
}

.btn.dropdown-toggle:focus,
.bootstrap-select.btn-group .dropdown-menu li:focus,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    outline: none !important;
}

.dropdown-menu {
    @include border-radius(1px);
}

header {
    text-align: center;
    background: url("../img/new_img1.jpg") center top no-repeat transparent;
    background-size: cover;
    position: relative;
    background-attachment: fixed;

    >.padding {
        padding-top: 10px;
        height: calc(100vh - 80px);
        position: relative;
    }

    .gradientMask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: calc(100vh - 80px);
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }

    nav {
        position: relative;
        z-index: 2;

        ul {
            padding: 0;
            display: inline-block;
            margin: 10px 0 0 0;

            &.shortMenu {
                display: none;
            }

            li {
                display: inline-block;
                margin: 0 10px;
                text-transform: uppercase;
                font-size: 16px;
                font-family: 'barlow_semi_condensedmedium';

                a {
                    color: white;

                    &:hover,
                    &:focus {
                        color: $primary;
                        text-decoration: none;
                    }
                }
            }
        }

        .logo {
            position: absolute;
            left: 0;
            top: 0;
        }

        .tel {
            position: absolute;
            right: 0;
            top: 8px;

            .icon {
                background: url("../img/icons_i_phone_w.png") center center no-repeat transparent;
                width: 36px;
                height: 36px;
                display: inline-block;
                vertical-align: top;
            }

            span {
                display: inline-block;
                vertical-align: top;
                line-height: 30px;
                font-size: 20px;
                font-family: 'barlow_semi_condensedmedium';
                color: white;
            }
        }
    }

    .mainSlogan {
        color: white;
        margin-top: 20vh;
        position: relative;
        z-index: 2;

        h1 {
            letter-spacing: 0.01em;
            line-height: 69px;
            font-size: 55px;
            font-family: 'barlow_semi_condensedmedium';
            text-transform: uppercase;
            margin: 0;
            margin-top: 25px;

            span {
                display: inline-block;

                &::first-letter {
                    font-size: 70px;
                    font-family: 'barlow_semi_condensedsemibold';
                }
            }
        }

        h2 {
            font-size: 30px;
            font-family: 'barlow_semi_condensedmedium';
            line-height: 40px;
            margin: 0;
        }

        p {
            text-transform: uppercase;
            font-size: 16px;
            font-family: 'barlow_semi_condensedmedium';
            line-height: 25px;
            margin: 25px 0 0;
        }
    }
}

div.info {
    position: relative;
    z-index: 2;
    padding-bottom: 100vh;

    .padding {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        background: white;
        max-width: none;
    }

    h2 {
        margin: 0;
        font-size: 60px;
        font-family: 'barlow_semi_condensedmedium';
        color: #000000;
        line-height: 70px;
    }

    p {
        margin: 30px 0 0 0;
        font-size: 30px;
        font-family: 'barlow_semi_condensedmedium';
        line-height: 40px;
    }
}

div.secondPhoto {
    display: none;

    img {
        width: 100%;
    }
}

.vertical {
    div.secondPhoto {
        display: block;
    }

    div.info {
        padding-bottom: 0;
    }
}

div.about {
    figure {
        padding-top: 36px;
        padding-bottom: 44px;
        text-align: center;

        i {
            width: 100%;
            display: block;
            height: 90px;
        }

        h3 {
            font-size: 30px;
            font-family: 'barlow_semi_condensedmedium';
            line-height: 40px;
            margin: 12px 0 5px 0;
        }

        p {
            font-size: 18px;
            line-height: 30px;
            margin: 0;
        }

        &.area i {
            background: url("../img/bullet_i_okolica_2.png") center center no-repeat transparent;
        }

        &.connection i {
            background: url("../img/bullet_i_polaczenia.png") center center no-repeat transparent;
        }

        &.ground i {
            background: url("../img/bullet_i_oddane_domy.png") center center no-repeat transparent;
        }
    }

    .points {
        display: table;
        width: 100%;
        border: 1px solid $border;
        table-layout: fixed;
        margin-bottom: 40px;

        >div {
            display: table-cell;
            text-align: center;
            border: 1px solid $border;

            p {
                margin: 0;
                font-size: 20px;
                padding: 20px 0;

                span {
                    display: block;
                    font-size: 30px;
                    font-family: 'barlow_semi_condensedmedium';
                    line-height: 40px;
                }
            }
        }
    }
}

div.content {
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;

    .gradientMask {
        display: none;
    }

    &.collapsed {
        height: 400px;

        .gradientMask {
            display: block;
        }
    }

    .photo {
        img {
            width: 100%;
        }
    }

    .desc {
        p {
            font-size: 18px;
            text-align: left;
            letter-spacing: 0.01em;
            line-height: 30px;
            margin: 0 0 30px;

            &:last-child {
                margin: 0;
            }
        }
    }

    &.amenities {
        .desc {
            p {
                padding-left: 15px;
            }
        }
    }

    .gradientMask {
        position: absolute;
        z-index: 10;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+56,1+100 */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 56%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 56%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 56%, rgba(255, 255, 255, 1) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);

        /* IE6-9 */
        a {
            position: absolute;
            font-size: 16px;
            font-family: 'barlow_semi_condensedmedium';
            color: $primary;
            letter-spacing: 0.01em;
            line-height: 25px;
            left: 50%;
            bottom: 10px;
            transform: translate(-50%, -50%);
            background: url(../img/icons_i_chevron_left1.png) center right no-repeat transparent;
            padding-right: 30px;
        }
    }
}

.sectionTitle {
    position: relative;
    text-align: center;
    margin-bottom: 45px;

    &:before {
        content: '';
        height: 2px;
        background: $border;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -1px;
        z-index: 0;
    }

    span {
        font-size: 20px;
        font-family: 'barlow_semi_condensedmedium';
        letter-spacing: 0.5em;
        line-height: 30px;
        text-transform: uppercase;
        background: white;
        padding: 0 20px;
        position: relative;
        z-index: 1;
    }
}

div.map {
    .icons {
        width: 100%;
        font-size: 0;

        >div {
            display: inline-block;
            width: 14.28%;
            vertical-align: top;
            text-align: center;

            i {
                display: block;
                height: 48px;
            }

            p {
                font-size: 16px;
                letter-spacing: 0.01em;
                line-height: 25px;
            }
        }
    }

    #map {
        width: 100%;
        height: 480px;
        margin: 23px 0 40px;

    }
}

.bootstrap-select>.dropdown-toggle,
.nya-bs-select>.dropdown-toggle {
    @include border-radius(1px);
    border: 2px solid $border;
    font-size: 20px;
    padding: 0 20px 0 10px;
    height: 40px;
    line-height: 36px;
}

.slider.slider-horizontal .slider-track {
    height: 2px;
    margin-top: -1px;
}

.slider-tick {
    border: 2px solid #ddd;
    box-shadow: none;
    opacity: 1;
    background: white;
}

.slider-handle,
.slider-tick.in-selection {
    background: $primary;
    border: 3px solid #000;
}

.slider-selection.tick-slider-selection,
.slider.slider-horizontal .slider-selection {
    background: #000;
    height: 6px !important;
    top: -2px !important;
}

.slider .tooltip.top {
    margin-top: -48px;
}

.slider.slider-horizontal {
    margin-top: 43px;

    .tooltip-inner {
        @include border-radius(1px);
        background: url("../img/slider_tooltip.png") center top no-repeat transparent;
        width: 36px;
        height: 40px;
        color: #000;
        font-size: 20px;
        font-family: 'barlow_semi_condensedmedium';
        letter-spacing: 0.01em;
        padding: 0;
        line-height: 32px;
    }

    .tooltip.bottom {
        .tooltip-inner {
            background: url("../img/slider_tooltip_bottom.png") center top no-repeat transparent;
            line-height: 42px;
        }
    }

    .tooltip .tooltip-arrow {
        display: none;
    }
}

.slider.slider-horizontal .slider-handle:hover {
    cursor: -webkit-grab;
    cursor: grab;
}

div.search {
    h3 {
        border-top: 2px solid $border;
        font-size: 30px;
        font-family: 'barlow_semi_condensedmedium';
        text-align: left;
        letter-spacing: 0.01em;
        line-height: 40px;
        text-transform: uppercase;
        margin-top: 0px;
        margin-bottom: 10px;
        padding-top: 43px;
    }

    #showSearchModal {
        display: none;
    }

    .searchForm {
        height: 100px;
        font-size: 0;
        margin: 0 -10px 40px;

        >div {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            padding: 0 10px;

            label {
                font-size: 16px;
                font-family: 'barlow_semi_condensedmedium';
                letter-spacing: 0.3em;
                line-height: 25px;
                text-transform: uppercase;
                display: block;
            }
        }

        .dropdown-menu {
            z-index: 1080;
        }

        .btn.btn-primary {
            width: 100%;
            height: 40px;
            font-size: 20px;
        }

        .slider.slider-horizontal {
            width: 80%;
        }

        .floor {
            width: 10%;

            .bootstrap-select,
            .nya-bs-select {
                width: 70px;
            }
        }

        .rooms {
            width: 25%;

            .tooltip.bottom {
                visibility: hidden;
            }
        }

        .space {
            width: 25%;
        }

        .other {
            width: 25%;

            .bootstrap-select,
            .nya-bs-select {
                width: 100%;
            }
        }

        .search {
            width: 15%;
        }
    }

    .table {
        .tableHeader {
            display: table;
            width: 100%;
            border-top: 2px solid $border;
            border-bottom: 2px solid $border;

            >div {
                display: table-cell;
                padding: 15px 10px;

                a {
                    text-transform: uppercase;
                    color: #666;
                    font-size: 16px;
                    font-family: 'barlow_semi_condensedmedium';
                    line-height: 25px;
                    padding-right: 25px;
                    cursor: pointer;

                    &.sortUp {
                        background: url("../img/icons_i_arr_drpdown2-up.png") right center no-repeat transparent;
                    }

                    &.sortDown {
                        background: url("../img/icons_i_arr_drpdown2.png") right center no-repeat transparent;
                    }

                    &.active {
                        color: $color_main;
                    }
                }
            }
        }

        .col1 {
            width: 96px;
            min-width: 96px;
        }

        .col2 {
            width: 91px;
            min-width: 91px;
        }

        .col3 {
            width: 160px;
            min-width: 127px;
        }

        .col4 {
            width: 145px;
        }

        .col5 {
            width: 140px;
        }

        .col6 {
            width: 145px;
        }

        .col7 {
            width: 200px;
        }

        .col9 {
            width: 90px;
        }

        .tableContent {
            .list {
                width: 100%;
                display: table;

                >div {
                    display: table-row;
                }

                >div>div {
                    display: table-cell;
                    padding: 5px 10px;
                    border-bottom: 1px solid $border;
                    font-size: 16px;
                    vertical-align: middle;

                    span {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    label {
                        display: none;
                    }
                }

                >div:nth-child(even) {
                    background: $bg_light;
                }

                >div:hover {
                    background: $hover;
                }

                .btn {
                    font-size: 16px;
                    font-family: 'barlow_semi_condensedmedium';
                    color: $primary;
                    text-transform: uppercase;
                    padding: 0 10px;
                    line-height: 26px;
                    background: transparent;
                    @include border-radius(1px);

                    &:hover {
                        background: $primary;
                        color: white;
                    }
                }

                .notAvailable {
                    color: #ccc;

                    .btn {
                        display: none;
                    }
                }
            }
        }
    }
}

div.searchFixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 10;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0 4px 0px #000000;

    h3 {
        border-top: none;
    }
}

div.searchInfo {
    position: fixed;
    left: 0;
    bottom: -55px;
    right: 0;
    background: white;
    z-index: 20;
    box-shadow: 0px 0 4px 0px #000000;
    text-align: right;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    p,
    a {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
    }

    p {
        font-size: 16px;
        font-family: 'barlow_semi_condensedmedium';
        line-height: 25px;
        text-transform: uppercase;
    }

    a {
        height: 26px;
        padding: 0 15px;
        line-height: 26px;
        font-size: 16px;
        margin-left: 20px;
    }

    .big {
        display: none;
    }

    .padding {
        padding: 10px 36px;
    }
}

div.contact {
    p.head {
        font-size: 45px;
        font-family: 'barlow_semi_condensedmedium';
        text-align: center;
        line-height: 55px;
        margin: 45px 0;
    }

    .frame {
        background: $bg_light;
        border: 2px solid $border;
    }

    .row {
        margin: 0;

        >div {
            padding: 0;

            >div {
                padding: 30px;
            }
        }
    }

    .col-left {
        border-right: 2px solid $border;
        padding: 30px;

        p {
            font-size: 18px;
            font-family: 'barlow_semi_condensedmedium';
            line-height: 30px;
            margin: 0;
        }

        p.big {
            font-size: 30px;
            font-family: 'barlow_semi_condensedmedium';
            line-height: 40px;
            text-transform: uppercase;
        }

        small {
            font-size: 14px;
            line-height: 20px;
        }

        .call {
            margin-top: 25px;

            >div {
                display: inline-block;
                vertical-align: top;
                padding-right: 20px;
            }

            .big {
                font-size: 18px;
                font-family: 'barlow_semi_condensedmedium';
                line-height: 30px;
            }

            .number {
                font-size: 30px;
                font-family: 'barlow_semi_condensedmedium';
                line-height: 40px;
                background: url("../img/icons_i_phone.png") left center no-repeat transparent;
                padding-left: 40px;
            }

            a {
                font-size: 30px;
                font-family: 'barlow_semi_condensedmedium';
                color: $primary;
                text-decoration: underline;
                line-height: 34px;
            }
        }

        .contact-ty {
            height: 251px;

            p {
                font-size: 18px;
                font-family: 'barlow_semi_condensedmedium';
                color: #000000;
                text-align: left;
                line-height: 30px;
                margin: 20px 0 10px 0;
                text-transform: uppercase;
            }
        }

        form {
            max-width: 600px;

            .row {
                margin: 0 -5px 0 -5px;

                >div {
                    padding: 5px;
                }
            }

            p {
                font-size: 18px;
                font-family: 'barlow_semi_condensedmedium';
                color: #000000;
                text-align: left;
                line-height: 30px;
                margin: 20px 0 10px 0;
                text-transform: uppercase;
            }

            .form-control {
                @include border-radius(1px);
                border: 2px solid #dddddd;
            }

            textarea {
                resize: none;
                height: 115px;
            }

            button.btn-primary {
                font-size: 20px;
                min-width: 145px;
            }
        }

        .logo {
            width: 88px;
            margin-bottom: 20px;
        }
    }

    .col-right {
        p {
            font-size: 14px;
            color: #666666;
            text-align: left;
            line-height: 20px;
            margin: 20px 0;
        }
    }
}

footer {
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 30px;
    padding: 40px 0;
}

.modalCustom {
    padding: 50px;

    .ngdialog-content {
        background: white;
        border: 1px solid #000;
        width: 90%;
        max-width: 1320px;
        box-shadow: 0 0 20px 0px #000;
        margin: 0 auto;
    }

    .modalHeader {
        background: #000;
        padding: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            color: white;

            li {
                text-transform: uppercase;
                border-right: 1px solid white;
                font-size: 30px;
                font-family: 'barlow_semi_condensedmedium';
                color: #ffffff;
                text-align: center;
                letter-spacing: 0.01em;
                line-height: 40px;
                padding: 0 20px;
                display: inline-block;
            }
        }

        .buttons {
            margin-top: 10px;

            a {
                vertical-align: top;
            }

            a:last-child {
                margin-bottom: 0;
            }
        }

        .btn {
            font-size: 16px;
            margin-top: 3px;

            &.btn-primary {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .closeBtn {
            background: url("../img/icons_i_close_w.png") center center no-repeat transparent;
            width: 34px;
            height: 34px;
            margin-left: 20px;
            margin-top: 3px;
        }
    }

    .modalContent {
        font-size: 0;
        position: relative;

        .colLeft {
            width: 65%;
            display: inline-block;
            vertical-align: top;
            padding: 0;
            border-right: 1px solid $border;

            img {
                width: 100%;
            }
        }

        .colRight {
            display: inline-block;
            width: 35%;
            font-size: 16px;
            position: relative;

            img {
                width: 100%;
            }
        }

        .flatInfo,
        .flatAdress {
            padding: 15px;
            border-top: 1px solid $border;

            p {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
            }
        }

        .flatInfo>p {
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .building {
            padding: 0;
            text-align: center;
        }

        .flatSummary {
            border-top: 1px solid $border;
            padding: 15px 15px 15px;

            p {
                margin: 0;
                font-size: 16px;
                font-family: 'barlow_semi_condensedmedium';
                text-align: left;
                line-height: 25px;
                text-transform: uppercase;

                span {
                    float: right;
                    font-size: 30px;
                    line-height: 40px;
                    margin-top: -12px;
                }
            }
        }
    }
}

.modalSearch {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    padding: 15px;
    display: none;
    z-index: 1080;

    .searchHeader {
        padding-bottom: 13px;
        border-bottom: 2px solid $border;

        p {
            margin: 0;
            font-size: 23px;
            font-family: 'barlow_semi_condensedmedium';
            text-align: left;
            line-height: 30px;
            text-transform: uppercase;
        }

        .closeBtn {
            width: 32px;
            height: 32px;
            background: url("../img/icons_i_close.png") center center no-repeat transparent;
            float: right;
            display: block;
            margin-right: -10px;
        }
    }

    div.search .searchForm {
        display: block;

        .btn.btn-primary {
            width: 100%;
            margin-top: 20px;
        }

        >div {
            display: block;
            width: 100%;
            border-bottom: 1px solid $border;
            padding-bottom: 15px;
            margin: 10px 0 10px;

            &:last-child {
                border-bottom: none;
            }

            label {
                text-align: left;
            }
        }

        .other {
            position: relative;
            border: none;
            margin-bottom: 0;
        }

        .search {
            margin-top: 0;

            label {
                display: none;
            }
        }
    }
}

@media all and (min-width:1360px) {
    div.searchInfo {
        text-align: left;
        bottom: -90px;

        p {
            font-size: 26px;
            line-height: 60px;
        }

        .small {
            display: none;
        }

        .big {
            display: block;
            float: right;
            font-size: 26px;
            height: 45px;
            line-height: 45px;
            padding: 0 30px;
            margin-top: 7px;
        }
    }

    .modalCustom .modalHeader .buttons {
        position: absolute;
        right: 40px;
        top: 0;
    }
}

@media all and (max-width:1120px) {
    header nav ul.shortMenu {
        display: inline-block;
    }

    header .extendMenu {
        position: fixed;
        left: 0;
        top: -250px;
        right: 0;
        background: white;
        z-index: 10;
        margin: 0;
        display: block;
        box-shadow: 0px 0 4px 0px #000000;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    header nav ul.extendMenu li {
        display: block;
        text-align: center;
        margin: 10px 0;

        a {
            color: #000;

            &:hover {
                color: $primary;
            }
        }
    }
}

@media all and (max-width:992px) {
    div.info h2 {
        font-size: 30px;
        line-height: 40px;
    }

    div.info p {
        margin: 10px 0 0 0;
        font-size: 23px;
        font-family: 'barlow_semi_condensedmedium';
        line-height: 40px;
    }

    div.info .padding {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    div.content.amenities .desc p {
        padding: 0;
    }

    div.about {
        padding-top: 30px;

        .row {
            width: 50%;
            display: inline-block;
            vertical-align: top;
        }
    }

    div.about .points {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        border: none;
        float: right;
    }

    div.about .points>div {
        border: 2px solid $border;
        display: block;
        margin-left: 30px;
        margin-top: -2px;
    }

    div.about figure {
        padding-top: 0;
    }

    div.about .points>div p span {
        font-size: 23px;
        line-height: 30px;
    }

    div.about .points>div p {
        font-size: 16px;
        line-height: 25px;
        padding: 15px 0;
    }

    div.about figure h3 {
        font-size: 23px;
        line-height: 30px;
    }

    div.about figure p {
        font-size: 16px;
        line-height: 25px;
    }

    div.content .desc p {
        font-size: 16px;
        line-height: 25px;
        margin: 0 0 20px;
    }

    div.map .icons {
        img {
            transform: scale(0.5);
        }
    }

    div.map .icons>div p {
        font-size: 12px;
        letter-spacing: 0.01em;
        line-height: 25px;
        margin: 0;
    }

    div.map .icons>div i {
        height: 35px;
    }

    div.map .mapContainer {
        margin: 10px 0 30px;
    }

    div.search h3 {
        font-size: 23px;
        line-height: 30px;
        padding-top: 20px;
    }

    div.contact p.head {
        font-size: 30px;
        line-height: 40px;
        margin: 30px 0;
    }

    div.contact .col-left {
        border: none;
    }

    div.contact .col-right {
        border-top: 2px solid $border;
    }

    div.contact .row>div>div {
        padding: 15px;
    }

    div.contact .col-left p.big {
        font-size: 23px;
        line-height: 30px;
    }

    div.contact .col-left p {
        font-size: 16px;
        line-height: 25px;
    }

    div.contact .col-left small {
        font-size: 14px;
        line-height: 20px;
    }

    div.contact .col-left .call .big {
        font-size: 16px;
        line-height: 25px;
    }

    div.contact .col-left .call .number {
        font-size: 23px;
        line-height: 30px;
    }

    div.contact .col-left .call a {
        font-size: 23px;
        line-height: 30px;
    }

    .padding {
        padding: 0 20px;
    }

    div.searchFixed {
        display: none;
    }

    header nav {
        z-index: 3;
    }

    div.search .searchForm .floor {
        width: 100%;
        text-align: left;
        padding: 0 0 20px 0;
        border-bottom: 1px solid $border;
        margin-bottom: 20px;
    }

    div.search .searchForm {
        height: auto;
        position: relative;
        margin: 0 0 30px;
    }

    div.search .searchForm .space,
    div.search .searchForm .rooms {
        width: 50%;
    }

    div.search .searchForm .other {
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;
    }

    div.search .searchForm .search {
        width: 100%;
        margin-top: 20px;
        border-top: 2px solid $border;
    }

    div.search .searchForm .btn.btn-primary {
        width: 50%;
    }

    div.search .searchForm>div {
        padding: 0;
    }

    div.map #map {
        height: 290px;
    }

    div.search .table .tableHeader {
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        display: block;

        >div {
            display: inline-block;
            width: auto;
        }
    }

    div.search .table .tableContent .list,
    div.search .table .tableContent .list>div {
        display: block;
        font-size: 0;
    }

    div.search .table .tableContent .list>div>div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        border: none;
        text-align: right;
        padding: 2px 20px;
    }

    div.search .table .tableContent .list>div>div.col9 {
        width: 100%;
    }

    div.search .table .tableContent .list>div {
        border-bottom: 1px solid $border;
        padding: 10px 0;
    }

    div.search .table .tableContent .list>div>div label {
        display: inline-block;
        float: left;
        font-size: 16px;
        font-family: 'barlow_semi_condensedregular';
        margin: 0;
    }


    div.search .table .tableContent .list>div>div span {
        max-width: 60%;
    }

    div.search .table .tableContent .list .btn {
        color: white;
        background: $primary;
    }

    .modalCustom .modalHeader ul li {
        text-transform: unset;
        border-right: none;
        font-size: 16px;
        font-family: 'barlow_semi_condensedmedium';
        color: #ffffff;
        line-height: 25px;
        padding: 0 10px;
    }

    .modalCustom .modalHeader .closeBtn {
        height: 26px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .modalCustom .modalHeader .btn {
        font-size: 16px;
        line-height: 26px;
        padding: 0 10px;
        margin: 0;
    }

    .modalCustom .modalContent .colLeft {
        width: 100%;
        display: block;
        padding: 20px;
    }

    .modalCustom .modalContent .colRight {
        width: 100%;
        display: block;
        font-size: 0;
        border-top: 1px solid #ddd;
    }

    .modalCustom .modalContent .building {
        width: 50%;
        vertical-align: top;
        display: inline-block;
        padding: 20px;
    }

    .modalCustom .modalContent .flatInfoContainer {
        width: 50%;
        vertical-align: top;
        display: inline-block;
        font-size: 16px;
        border-left: 1px solid $border;
    }

    .modalCustom .modalContent .flatAdress {
        border-top: none;
    }

    .modalCustom .modalContent .flatSummary p span {
        font-size: 23px;
    }

    div.contact .col-left form {
        max-width: 100%;
    }

    div.contact .col-left .call {
        font-size: 0;
    }

    div.contact .col-left .call>div {
        width: 50%;
    }

    div.contact .col-left .call .email {
        padding-left: 5px;
    }
}

@media all and (max-width:575px) {
    header nav ul.shortMenu {
        margin-top: 65px;
    }

    header .mainSlogan img {
        width: 80px;
    }

    .padding {
        padding: 0 10px;
    }

    header .mainSlogan h1 {
        line-height: 50px;
        font-size: 43px;
    }

    header .mainSlogan h1 span {
        font-size: 30px;
    }

    header .mainSlogan h2 {
        font-size: 23px;
        line-height: 30px;
    }

    header .mainSlogan p span {
        display: block;
    }

    header nav .tel .icon {
        background-size: cover;
        width: 25px;
        height: 25px;
    }

    header nav .tel span {
        font-size: 16px;
    }

    div.info p {
        font-size: 18px;
        line-height: 17px;
    }

    div.info .padding {
        padding: 20px 0 30px;
    }

    div.about .row {
        width: 100%;
        display: block;
        margin: 0;
    }

    div.about figure {
        margin-bottom: 0;
        padding-bottom: 30px;
    }

    div.about .points {
        display: block;
        width: 100%;
        float: none;
        margin: 0;
    }

    div.about .points>div {
        margin-left: 0;
    }

    .sectionTitle {
        margin: 20px 0;
    }

    .sectionTitle:before {
        display: none;
    }

    .sectionTitle span {
        padding: 0;
        font-size: 19px;
        letter-spacing: 0.5em;
        line-height: 30px;
    }

    div.content {
        padding-bottom: 0px;
    }

    div.map .icons {
        text-align: center;
    }

    div.map .icons>div {
        width: 23.28%;
    }

    div.search .table .tableContent .list>div>div {
        width: 100%;
    }

    div.search h3 {
        font-size: 19px;
        line-height: 30px;
        text-align: center;
        padding-top: 10px;
    }

    div.searchInfo {
        text-align: left;
    }

    div.searchInfo .padding {
        padding: 10px 10px;
    }

    div.searchInfo a {
        padding: 0 10px;
        margin-left: 0;
        float: right;
    }

    .modalCustom .modalContent .building {
        width: 100%;
    }

    .modalCustom .modalContent .flatInfoContainer {
        width: 100%;
        border-left: 0;
    }

    .modalCustom .modalContent .flatSummary p {
        font-size: 13px;
    }

    .modalCustom .modalContent .flatAdress {
        border-top: 1px solid $border;
    }

    .modalCustom .modalHeader ul li {
        padding: 0;
    }

    div.search .searchForm {
        display: none;
    }

    div.search #showSearchModal {
        display: block;
        font-size: 22px;
        padding: 0;
        line-height: 40px;
        margin-bottom: 32px;
    }

    div.contact .col-left .call>div {
        width: 100%;
    }

    div.contact .col-left .call .email {
        padding: 20px 0 0 0;
    }
}

.ngdialog-close:before {
    content: '' !important;
}

.ngdialog-open {
    height: auto !important;
}

/* The location pointed to by the popup tip. */
.popup-tip-anchor {
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom:
        /* TIP_HEIGHT= */
        8px;
    left: 0;
}

/* Draw the tip. */
.popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top:
        /* TIP_HEIGHT= */
        8px solid white;
}

/* The popup bubble itself. */
.popup-bubble-content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the info window. */
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    max-height: 108px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}